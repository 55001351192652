@import "../../assets/sass/media-queries";

.projects {
    padding: 0 95px;
    @include tablet {
        padding: 0;
    }

    @include mobile {
     padding: 0;
    }

    h1 {
        margin: 0;
        color: white;

        @include tablet {
           text-align: center;
        }
    
        @include mobile {
            text-align: center;
        }
       
    }
    .gridContainer {
        margin-top: 50px;
        display: grid;
        gap: 1rem;
        grid-auto-rows: 313px;
        grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));

        @include tablet {
            padding: 30px;
         }
     
         @include mobile {
            padding: 30px;
         }
        
     



        .items {
            overflow: hidden;
            position: relative;
            z-index: 1;

            img {
                width: 162%;
                position: absolute;
                right: -47px;

                &:hover {
                    transition: ease 0.5s;
                    transform: scale(1.4);

                }
            }


            .layer {
                background: #13273a;;
                width: 100%;
                position: relative;
                top: 55%;
                z-index: 2;
                height: 160px;
                left: 50%;
                border-radius: 158px 0 0 0;
                border: 2px solid white;


                .description {
                    background: linear-gradient(90deg, rgba(11,40,60,1) 0%, rgba(0,0,0,1) 99%, rgba(8,28,41,1) 100%);
                    width: 100%;
                    position: absolute;
                    top: 26%;
                    z-index: 2;
                    height: 160px;
                    left: 20%;
                    border-radius: 158px 0 0 0;

                

                    .stack {
                        width: 104px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        height: 24px;

                        p{
                            color: white;
                            margin-left: 5px;
                            letter-spacing: 1px;
                            font-size: 11px;
                            font-weight: 500;
                        
                        }

                    }
                }


            }

        }



    }


}

a {
    display: grid;
}