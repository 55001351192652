#root{
  background: #101f2e;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




@keyframes show {
  from{
    opacity: 0;
    scale: 25%;
  }
  to{
    opacity: 1;
    scale: 100%;
  }
}

#PROJECTS{
  view-timeline-name: --image;
  view-timeline-axis: block;
  animation-timeline: --image;
  animation-name: show;
  animation-range: entry 25% cover 50%;
}

#EXPERIENCE{
  view-timeline-name: --image;
  view-timeline-axis: block;
  animation-timeline: --image;
  animation-name: show;
  animation-range: entry 25% cover 50%;
}

