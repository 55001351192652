@import "../../assets/sass/media-queries";

.row {


    @include tablet {
        background: linear-gradient(90deg, rgb(131, 58, 180) 0%, rgb(39, 29, 253) 50%, rgb(69, 251, 252) 100%);
        height: 82px;
        box-shadow: 2px 14px 40px 12px black;
        opacity: 1;
    }

    @include mobile {
        background: linear-gradient(90deg, rgb(131, 58, 180) 0%, rgb(39, 29, 253) 50%, rgb(69, 251, 252) 100%);
        height: 82px;
        box-shadow: 2px 14px 40px 12px black;
        opacity: 1;
    }
}

.firstIndex{
    position: relative;
    z-index: 2;
}

.language {
    position: absolute;
    top: 28px;
    left: 7%;
    cursor: pointer;
    @include tablet {
        position: relative;
        z-index: 3;
    }
    @include mobile {
        position: relative;
        z-index: 3;
    }


}

.menu {
    display: none;

    @include tablet {
        display: block;
        position: absolute;
        top: 28px;
        left: 85%;
        cursor: pointer;
        z-index: 3;
    }

    @include mobile {
        display: block;
        position: absolute;
        top: 28px;
        left: 85%;
        cursor: pointer;    
        z-index: 3;
    }
}

.close{

    @include tablet {
        display: block;
        position: absolute;
        top: 28px;
        left: 85%;
        cursor: pointer;
        z-index: 3;
    }

    @include mobile {
        display: block;
        position: absolute;
        top: 28px;
        left: 85%;
        cursor: pointer;
        z-index: 3;
    }

}


ul {
    margin: 0;
    padding: 22px;
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: center;
 

    li {
        padding: 13px;
        animation: 1s ease all;
        border: 1px solid white;
      
        transition: ease 0.3s;
            transform: translateY(-3px);
            border-radius: 50px;
            cursor: pointer;
            box-shadow: 0px 5px 0px white;
            background: #131f2d;


      

        @include tablet {
            border: none;
            position: relative;
            top: 47.33px;
            padding-left: 0;
            margin-left: 0;
            background: transparent; 
            box-shadow: none;

        }

        @include mobile {
            border: none;
            position: relative;
            padding-left: 0;
            margin-left: 0;
            background: transparent;
            box-shadow: none;
            


            &:hover {
              background: none;
              box-shadow: none;
            }
        }



    }

    @include tablet {
        display: none;
        
    }

    @include mobile {
        display: none;
    }




}

.openMenu {
    animation: openingOptions 0.3s ease-in-out;

    @include tablet {
        display: flex;
        margin: 0;
        padding: 0 78px 78px 78px;
        flex-direction: column;
        align-items: center;
        position: relative;
        top: -6px;
        z-index: 1;
        background: linear-gradient(90deg, rgb(131, 58, 180) 0%, rgb(39, 29, 253) 50%, rgb(69, 251, 252) 100%);
        position: relative;
        z-index: 2;
        height: 200px;
        border-radius: 4px;
    
    }

    @include mobile {
        display: flex;
        margin: 0;
        padding: 0 78px 78px 78px;
        flex-direction: column;
        align-items: center;
        position: relative;
        top: -6px;
        z-index: 1;
        background: linear-gradient(90deg, rgb(131, 58, 180) 0%, rgb(39, 29, 253) 50%, rgb(69, 251, 252) 100%);
        height: 200px;
        border-radius: 4px;
        
    }

    @keyframes openingOptions {
        0%{
            transform: translate(0, -300px);
            opacity: 0;
            height: 0;
        }
        100%{
            transform: translate(0, 0px);
            opacity: 1;
            height: 200px;
        }
    }
}