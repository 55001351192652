@import "../../assets/sass/media-queries";



.body {

    height: 64vh;
    display: flex;
    flex-direction: row;
    padding: 0 95px;
    margin-top: 16px;
    align-items: center;

    .zindex {
        position: relative;
        z-index: 1;
    }

    .body-text {
        width: 90vw;
        display: flex;
        flex-direction: column;
        padding: 0;
        justify-content: flex-start;



        h1 {
            font-size: 25px;
            color: #50b1c7;
            margin-top: 20px;
            display: flex;
            align-items: center;

            @include tablet {
                text-align: center;
                color: #50b1c7;
                display: block;
                font-size: 22px;
                margin-top: 33px;
                overflow-wrap: anywhere;
                width: 100%;
            }

            @include mobile {
                text-align: center;
                justify-content: center;
                color: #50b1c7;
                display: block;
                font-size: 22px;
                margin-top: 33px;
                overflow-wrap: anywhere;
                width: 100%;
            }

            img:nth-child(1) {
                width: 24px;
                transform: rotate(0deg);
                margin-right: 13px;
                animation: animateHand 1s ease infinite;
            }

            img:nth-child(2) {
                width: 24px;
                margin-left: 10px;

            }

     
        }

        h2 {

            color: white;
            font-size: 53px;
            margin: 0;
            padding: 0;
            background-image: linear-gradient(to right, #ffcc00, #ff6699);
            -webkit-background-clip: text;
            color: transparent;




            @include tablet {
                text-align: center;
            }

            @include mobile {
                text-align: center;
            }



        }

        small {
            margin-top: 10px;
            color: white;
            font-size: 22px;
            letter-spacing: 0.5px;

            @include tablet {
                text-align: center;
            }

            @include mobile {
                text-align: center;
            }

            img {
                width: 24px;
                margin-right: 5px;
                margin-left: 0;
                position: relative;
                top: 5px;
            }

            img:nth-child(3) {
            
                margin-left: 10px;
            
            }
        }

        p {
            margin-top: 55px;
            font-size: 20px;
            color: white;
            letter-spacing: 1px;
            font-weight: 600;

            @include tablet {
                text-align: center;
            }

            @include mobile {
                text-align: center;
            }
        }

        .media {
            display: flex;
            justify-content: space-between;
            width: 145px;
            cursor: pointer;

            @include tablet {
                width: 100%;
                display: flex;
                justify-content: center;

            }

            @include mobile {
                width: 100%;
                display: flex;
                justify-content: space-around;

            }

            svg {
                @include tablet {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;

                }

                @include mobile {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;

                }
            }

        }

        @include tablet {
            padding: 0;
        }

        @include mobile {
            padding: 0;
        }
    }

    .body-image {
        margin-top: 35px;
        width: 60%;
        position: relative;
        display: flex;
        justify-content: center;

        .image {
            border: 1px solid white;
            width: 190px;
            height: 190px;
            background-image: url("../../assets/images/profile.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 50%;
            border: 10px solid #38809f;
            margin-top: 50px;
        }


    }

    @include tablet {
        height: auto;

        display: flex;
        flex-direction: column-reverse;
        padding: 5px;
        align-items: center;
    }
}


@keyframes animateHand {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(45deg);
    }

    from {
        transform: rotate(0deg);
    }


}