@import "../../assets/sass/media-queries";

.experience {
    display: flex;
    flex-direction: column;
    padding: 0 95px;
    margin-top: 80px;

    @include tablet {
        padding: 0;
    }

    @include mobile {
        padding: 0;
    }

    h2 {
        margin: 0;
        color: white;
        font-size: 30px;
        @include tablet {
           text-align: center;
        }
    
        @include mobile {
            text-align: center;
        }
    
    }

    .gridExp {
        display: flex;
        flex-direction: row;
        width: 100%;

        align-items: center;
        margin-top: 31px;
        gap: 16px;
        flex-wrap: wrap;

        .company {
            display: flex;
            flex-direction: column;
            background: white;
            padding: 22px;
            border-radius: 12px;
            height: 100px;
            background: transparent;
            border: 2px solid #53d1ff;
            height: 100px;
            width: 320px;
        
            z-index: 1;

            @include tablet {
                flex-direction: column;
                width: 280px;
            }

            @include mobile {
                flex-direction: column;
                width: 280px
            }





            .title {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 100%;

                .info {
                    display: flex;
                    flex-direction: column;
                    margin-left: 22px;
                    width: 100%;

                    @include tablet {
                        flex-direction: column;
                        width: 100%;
                    }

                    @include mobile {
                        flex-direction: column;
                        width: 100%;
                    }

                    h4 {
                        
                        color: #53d1ff;
                        letter-spacing: 4px;
                        padding: 0;
                        margin: 0;

                    }

                    small {
                        
                        color: #53d1ff;
                        letter-spacing: 2px;
                    }

                    p {
                        color: #53d1ff;
                        
                        font-size: 9px;
                        padding: 0;
                        margin: 0;
                        letter-spacing: 1px;
                        overflow-wrap: anywhere;
                        font-weight: unset;
                        margin-top: 6px;
                        font-weight: 500;
                    }


                }

                img {
                    width: 110px;
                    cursor: pointer;
                    border-radius: 5px;

                    &:hover {
                        transition: ease all 1s;
                        transform: scale(1.02);
                    }
                }
            }

            p {
                color: black;
                font-weight: 500;
            }
        }

        @include tablet {
            flex-direction: column;
            width: 100%;
        }

        @include mobile {
            flex-direction: column;
            width: 100%;
        }
    }



}