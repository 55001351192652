@mixin tablet{
    @media screen and (max-width:992px) {
        @content;
    }
 
}

@mixin mobile{
    @media screen and (max-width:992px) {
        @content;
    }
 
}

@mixin no-hover-effect {
    @media (hover: none) {
      @content;
    }
  }