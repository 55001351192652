@import "../../assets/sass/media-queries";

.about {
    display: flex;
    flex-direction: column;
    padding: 0 95px;
    margin-top: 80px;
    @include mobile {
        padding: 0 40px 0 40px;
        margin-top: 43px;
    }

    h2 {
        margin: 0;
        color: white;
        font-size: 30px;
        margin: 0 0 30px 0;
        @include mobile {
            text-align: center;
        }
    }

    .contact {
        border-top: 2px solid #53d1ff;
        display: flex;
        flex-direction: column;


    }

    footer {
        flex-direction: column;
        width: 100%;
        display: flex;

        .social-media {
            display: flex;
            flex-direction: row;
            justify-content: center;
            position: relative;
            bottom: 12px;
            background: #131f2d;
            width: 44px;
            left: calc(50% - 50px);
            padding: 40px;
            height: -32px;
            bottom: 51px;
            gap: 5px;

            @include tablet {
                display: flex;
                flex-direction: row;
                justify-content: center;
                position: relative;
                bottom: 12px;
                background: #131f2d;
                width: 44px;
                left: calc(50% - 50px);
                padding: 0 40px 0 0;
                height: -32px;
                bottom: 51px;
                gap: 5px;
            }

            @include mobile {
                display: flex;
                flex-direction: row;
                justify-content: center;
                position: relative;
                bottom: 12px;
                background: #131f2d;
                width: 40px;
                left: calc(50% - 60px);
                padding: 0 40px 0 40px;
                height: -32px;
                bottom: 19px;
                gap: 5px;
                
            }




            a {
                width: 35px;
            }



        }

        p {
            text-align: center;
            color: white;
            position: relative;
            bottom: 65px;

            @include tablet {
                position: relative;
                bottom: 0;
    
           
            }

            @include mobile {
                position: relative;
                bottom: 0;
            
                
            }
        }

    }
}